import React, { useRef, useState, useEffect } from "react";
import HTMLFlipBook from "react-pageflip";
import "../Styles/Secondb.css";

const images = ["1.jpg", "2.jpg", "3.jpg", "4.jpg"];

const ComicBook = () => {
  const flipBookRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 370, height: 570 });

  useEffect(() => {
    const updateDimensions = () => {
      if (window.innerWidth < 768) {
        // Example breakpoint for mobile
        setDimensions({ width: 300, height: 450 });
      } else if (window.innerWidth < 768) {
        setDimensions({ width: 370, height: 570 });
      }
    };

    updateDimensions(); // Set initial dimensions
    window.addEventListener("resize", updateDimensions); // Update dimensions on resize

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const handleNextPage = () => {
    if (flipBookRef.current) {
      flipBookRef.current.pageFlip().flipNext();
    }
  };

  const handlePrevPage = () => {
    if (flipBookRef.current) {
      flipBookRef.current.pageFlip().flipPrev();
    }
  };

  return (
    <div className="comic-book">
      <HTMLFlipBook
        width={dimensions.width}
        height={dimensions.height}
        ref={flipBookRef}
        className="flip-book"
      >
        {images.map((image, index) => (
          <div className="page" key={index}>
            <img src={image} alt={`Page ${index + 1}`} />
          </div>
        ))}
      </HTMLFlipBook>
      <div className="navigation-buttons">
        <button className="nav-button left" onClick={handlePrevPage}>
          &#9664; {/* Left Arrow */}
        </button>
        <button className="nav-button right" onClick={handleNextPage}>
          &#9654; {/* Right Arrow */}
        </button>
      </div>
    </div>
  );
};

export default ComicBook;
