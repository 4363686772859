import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import "../Styles/Firstb.css";

const FirstPage = () => {
  const controls = useAnimation();
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    // Set up image loading state
    const images = [
      "background-bottom-left.png",
      "background-bottom-right.png",
      "background-top-left.png",
      "background-top-right.png",
    ];

    const imgElements = images.map((src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
      });
    });

    Promise.all(imgElements).then(() => setImagesLoaded(true));

    // Add the class when the component mounts
    document.body.classList.add("hide-overflow");

    // Remove the class after the animation completes
    const timer = setTimeout(() => {
      document.body.classList.remove("hide-overflow");
    }, 1000); // Match the longest animation duration (0.7s)

    // Cleanup function to remove the class if the component unmounts
    return () => {
      clearTimeout(timer);
      document.body.classList.remove("hide-overflow");
    };
  }, []);

  useEffect(() => {
    if (imagesLoaded) {
      controls.start({ x: 0 });
    }
  }, [imagesLoaded, controls]);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      "145STQxT73hUc7Sqj5GAhkNS8MYAvEwXmwQ4xZ3GEape"
    );
    setShowCopiedMessage(true);
    setTimeout(() => setShowCopiedMessage(false), 1500);
  };
  return (
    <div className="FB-Container">
      <div className="background-container">
        <motion.img
          src="background-bottom-left.png"
          alt="didn't load properly"
          className="bg-btm-left corner"
          initial={{ x: -1000 }}
          animate={controls}
          transition={{ duration: 0.7 }}
        />
        <motion.img
          src="background-bottom-right.png"
          alt="didn't load properly"
          className="bg-btm-right corner"
          initial={{ x: 1000 }}
          animate={controls}
          transition={{ duration: 0.7 }}
        />
        <motion.img
          src="background-top-left.png"
          alt="didn't load properly"
          className="bg-tp-left corner"
          initial={{ x: -1000 }}
          animate={controls}
          transition={{ duration: 0.7 }}
        />
        <motion.img
          src="background-top-right.png"
          alt="didn't load properly"
          className="bg-tp-right corner"
          initial={{ x: 1000 }}
          animate={controls}
          transition={{ duration: 0.7 }}
        />
      </div>
      <div className="Header">
        <h1>$WOOLIE</h1>
        <img
          src="woolie.png"
          alt="error loading woolie"
          className="woolie-img"
        />
        {/* <h4 className="contract-address-bottom" style={{ color: "#7a2518" }}>
          CA:{" "}
          <span className="copyable-text" onClick={copyToClipboard}>
            
          </span>
        </h4> */}
        {/* {showCopiedMessage && (
          <span className="copied-message">Copied to clipboard</span>
        )} */}
        <div className="buttons">
          <a href="/" className="btn twitter" />
          <a href="/" className="btn telegram" />
          <a href="https://dexscreener.com" className="btn dexscreener" />
          <a href="https://www.dextools.io" className="btn dextools" />
        </div>
      </div>
    </div>
  );
};

export default FirstPage;
