import React from "react";
import "../Styles/Secondb.css";
import ComicBook from "./ComicBook";
const SecondPage = () => {
  return (
    <div className="SB-Container">
      <div className="SB-header">
        <h1>STORY TIME</h1>

        <p>
          Woolly mammoth are mammals that look similar to Asian Elephants. They
          have been extinct on earth since more than 10000 years ago, largely
          due to human exploitations as widely believed. The fact is that the
          species have actually moved to another planet through a portal not
          known to humankind…the planet is known to us as PLUTO.
        </p>
      </div>
      <div className="Book-Wrapper">
        <ComicBook />
      </div>
    </div>
  );
};

export default SecondPage;
