import React from "react";
import "../Styles/Thirdb.css";
const ThirdPage = () => {
  return (
    <div className="TB-Container">
      <div className="tokenomics-box">
        <div className="tokenomics-info">
          <h1>TOKENOMICS</h1>
          <p>
            team plan and goals for the tokenomics of this project, here is some
            of to-do list for the token.
          </p>
        </div>
        <div className="info-wrapper">
          <div className="info-left info">
            <h3>SUPPLY 1 BILLION</h3>
            <h3>MINT REVOKED</h3>
          </div>
          <div className="info-right info">
            <h3>TAX 0%</h3>
            <h3>OWNERSHIP RENOUNCED</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdPage;
